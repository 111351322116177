import React, { useEffect, useState, useCallback } from "react";
import api from "../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import URLModal from "../../pages/TaskList/TaskDetail/URLModal";
import {
  setIsBrowserActive,
  setIsBrowserDomain,
} from "../../store/slices/authSlice";
import {
  setTaskDomainData,
  domainReset,
} from "../../store/slices/taskPrevDomainSlice";
import TaskListCard from "./TaskListCard";
import TaskListHeader from "./TaskListHeader";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function TaskList() {
  const { user } = useSelector((state) => state.auth);
  const taskDomainData = useSelector((state) => state.taskDomainData);
  const dispatch = useDispatch();
  const { is_browser_active, is_browser_domain } = useSelector(
    (state) => state.auth
  );
  const [tasks, setTasksList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { linkBuilderId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const [isUrlModal, setIsUrlModal] = useState(false);
  const [filters, setFilters] = useState({
    groupOp: "",
    rules: [],
  });
  const [selectedStatus, setSelectedStatus] = useState(
    location?.state?.filter
      ? location?.state?.filter
      : queryParams.get("filter")
  );
  const [link_to_website, setLinkToWebsite] = useState("");
  const [isTaskStatus, setTaskStatus] = useState({});
  const [isStatusNote, setStatusNote] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedDomainId, setSelectedDomainId] = useState(
    taskDomainData ? taskDomainData?.data?.domain_id : ""
  );
  const [showFilters, setShowFilters] = useState(false);
  const [taskIds, setTaskIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    location?.state?.selectedDate || queryParams.get("selectedDate")
  );
  const timeConvert = (timestamp) => {
    const timezoneName = dayjs.tz.guess();
    dayjs.tz.setDefault(timezoneName);
    const parsedTime = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss");
    const newTime = parsedTime.add(5, "hour").add(30, "minute");
    const currentTime = dayjs(newTime).format("ddd, MMM D, YYYY h:mm A");
    return currentTime;
  };
  // Filters
  const [filterButton, setFilterButton] = useState("");

  const fetchTasksList = ({ filters, page, perPage }) => {
    setIsLoading(true);
    /* api
      .post(
        `/api/linkbuilder_user/tasks/${
          linkBuilderId || user?.user?.id
        }?page=${page}&perPage=${perPage}`,
        { filters }
      )
      .then((response) => {
        if (response.status === 200) {
          setTasksList(response.data);
          setIsLoading(false);
        } else {
          console.error(`Error: ${response.status}`);
          toast.error(response.status);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }); */
  };

  // Linkbuilders Users List
  const [LinkBuilders, setLinkBuilders] = useState([]);

  useEffect(() => {
    if (linkBuilderId) {
      setIsLoading(true);
      setFilterButton(selectedStatus);
      api
        .post(`/api/users/users_report`)
        .then((res) => {
          if (res.status === 200) {
            const selectedUser = res?.data?.records?.find((item) => {
              return item.id === parseInt(linkBuilderId, 10);
            });
            setLinkBuilderUser(selectedUser);
            setLinkBuilders(
              res.data?.records.filter(
                (item) => item.role_id === 3 || item.role_id === 8
              )
            );
          }
        })
        .catch((err) => console.log(err.data.message))
        .finally(() => setIsLoading(false));

      let newFilters;

      if (
        selectedStatus === "" ||
        selectedStatus === undefined
      ) {
        newFilters = {
          groupOp: "",
          rules: [],
        };
      } else {
        newFilters = {
          groupOp: "AND",
          rules: [
            {
              field: "task_status",
              op: "eq",
              data: selectedStatus,
            },
          ],
        };
        if (selectedDate) {
          newFilters.rules.push({
            field: "DATE(bdb.assigned_date)",
            op: "eq",
            data: selectedDate,
          });
        }
      }
      fetchTasksWithOpenStatus({
        page: paginationModel?.page,
        perPage: paginationModel?.pageSize,
        filters: newFilters,
      });
      setFilters(newFilters);
    } else {
      fetchTasksWithOpenStatus({
        page: paginationModel?.page,
        perPage: paginationModel?.pageSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Task Status Dropdown
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleTaskStatusDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const updateTask = async (taskId, task_status, prevStatus = null) => {
    setIsLoading(true);
    const taskErrors = {};
    if (prevStatus === "inReview" && task_status === "pending") {
      if (isStatusNote.trim() === "") {
        taskErrors.note_status = "Please enter a note.";
      } else {
        setErrors(taskErrors);
      }
      if (Object.keys(taskErrors).length > 0) {
        await setErrors(taskErrors);
        setIsLoading(false);
        return;
      }
    }
    const requestData = {
      task_status,
      ...(link_to_website && { link_to_website }),
      ...(isStatusNote && { note: isStatusNote }),
    };
    try {
      const res = await api.put(
        `/api/linkbuilder_user/update_task_status/${taskId.split(/[^0-9]/)[0]}`,
        requestData
      );
      if (res.status === 200) {
        if (isUrlModal) {
          setIsUrlModal(false);
          setLinkToWebsite("");
          setStatusNote("");
        }
        fetchTasksWithOpenStatus({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filters: filters,
        });
        toast.success("Task status updated");
      }
      //setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
  };
  // Pagination
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  /* const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchTasksWithOpenStatus({
      page: +params.page + 1,
      perPage: params.pageSize,
      filters: filters,
      source: "filter",
    });
  }; */
  // Users dropdown List
  const [linkBuilderUser, setLinkBuilderUser] = useState({ id: "" }); // Initialize with an empty ID
  const handleLinkBuilderUserTasksList = (event) => {
    const selectedUserId = event.target.value;
    // Navigate to the new URL
    navigate(`/settings/link-builders/${selectedUserId}`, {
      state: {
        filter: filterButton,
      },
    });

    // Refresh the page
    window.location.reload();
  };
  const handleStopBrowser = async () => {
    setIsLoading(true);
    try {
      const launch = await api.post("/api/multilogin/stop_browser");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            toast.success("Browser stopped successfully");
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          } else {
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          toast.error(
            urlLaunch?.data?.message || "Browser couldn't be stopped"
          );
        }
      } else {
        toast.error(launch?.data?.message || "Browser couldn't be stopped");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        toast.success("Browser stopped successfully");
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post("/api/multilogin/update_browser_status", {
          is_browser_active: 0,
        });
      }
      toast.error(
        error?.response?.data?.message || "Browser couldn't be stopped"
      );
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
    }
  };
  const navigate = useNavigate();
  const handleSelectedBatch = (batch) => {
    const TaskId = batch["id"].split(/[^0-9]/)[0];
    navigate(
      linkBuilderId
        ? `/settings/link-builders/${linkBuilderId}/${TaskId}`
        : `/task-list/${TaskId}`,
      {
        state: {
          domain_id: batch.domain_id,
          backlink_id: batch.backlink_id,
          batch_id: batch.batch_id,
          task_status: batch.task_status,
          ready_status_id: batch.ready_status_id,
          userId: user?.user?.id,
          filter: selectedStatus,
          taskIds: taskIds,
          taskDate: selectedDate,
        },
      }
    );
  };
  const fetchTasksWithOpenStatus = async ({
    filters,
    page,
    perPage,
    source = null,
  }) => {
    setIsLoading(true);
    const userId = linkBuilderId || user?.user?.id;
    const url = `/api/linkbuilder_user/tasks/${userId}?page=${page}&perPage=${perPage}`;
    try {
      const response = await api.post(url, { filters });
      if (response.status !== 200) {
        toast.error(response?.data?.message);
        setIsLoading(false);
        return;
      }
      const {
        linkbuilderTaskListByDomain,
        completed_tasks,
        hold_tasks,
        inProcess_tasks,
        inReview_tasks,
        pending_tasks,
        reOpen_tasks,
        count,
      } = response.data;
      const newDomainId = taskDomainData
        ? taskDomainData?.data?.domain_id
        : selectedDomainId;
      if (newDomainId && !source) {
        const isExpanded =
          taskDomainData && taskDomainData?.data?.domain_id
            ? true
            : expandedRows[newDomainId];
        setExpandedRows((prev) => ({
          ...prev,
          [newDomainId]: isExpanded,
        }));
        if (isExpanded) {
          const childData = await fetchChildData(
            newDomainId,
            taskDomainData?.data?.task_status,
            filters
          );
          const taskIds = childData?.map((item) => item.id);
          setTaskIds(taskIds);
          let uniqueChildData = childData.map((child, index) => ({
            ...child,
            id: `${child.id}-parent-${index}`,
          }));

          let filteredList = applyFilters(uniqueChildData, filters);
          const updatedList = linkbuilderTaskListByDomain.map((item) =>
            item.id === newDomainId
              ? { ...item, childRows: filteredList }
              : item
          );
          setTasksList({
            linkbuilderTaskListByDomain: updatedList,
            completed_tasks,
            hold_tasks,
            inProcess_tasks,
            inReview_tasks,
            pending_tasks,
            reOpen_tasks,
            count,
          });
        } else {
          setTasksList(response.data);
        }
      } else {
        setTasksList(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const applyFilters = useCallback((data, filters) => {
    if (!filters?.rules?.length) {
      return data;
    }
    const relevantRules = filters.rules.filter(
      (rule) => rule.field === "task_status"
    );
    return data.filter((item) => {
      return relevantRules.every((rule) => {
        const value = item[rule.field];
        switch (rule.op) {
          case "not":
            return value !== rule.data;
          case "cn":
            return value && value.includes(rule.data);
          case "eq":
            return value === rule.data;
          default:
            return true;
        }
      });
    });
  }, []);

  const handleExpandClick = async (id, status, childClick) => {
    dispatch(domainReset());
    setIsLoading(true);
    setSelectedDomainId(id);
    setExpandedRows({});
    setTasksList((prev) => ({
      ...prev,
      linkbuilderTaskListByDomain: prev.linkbuilderTaskListByDomain.map(
        (item) => ({
          ...item,
          childRows: [],
        })
      ),
    }));
    const shouldExpand = childClick || !expandedRows[id];

    if (shouldExpand) {
      const data = { domain_id: id, task_status: status };
      dispatch(setTaskDomainData(data));
      let childData = await fetchChildData(id, status, filters);
      const taskIds = childData.map((item) => item.id);
      setTaskIds(taskIds);
      let uniqueChildData = childData.map((child, index) => ({
        ...child,
        id: `${child.id}-parent-${index}`,
      }));
      let filteredList = applyFilters(uniqueChildData, filters);
      setTasksList((prev) => ({
        ...prev,
        linkbuilderTaskListByDomain: prev.linkbuilderTaskListByDomain.map(
          (item) =>
            item.id === id ? { ...item, childRows: filteredList } : item
        ),
      }));
      setExpandedRows((prev) => ({ ...prev, [id]: true }));
    }

    setIsLoading(false);
  };

  const rows = React.useMemo(() => {
    if (!tasks?.linkbuilderTaskListByDomain) return [];
    return tasks?.linkbuilderTaskListByDomain.flatMap((item) => {
      const parentRow = { id: item.id, ...item };
      if (expandedRows[item.id] && item.childRows) {
        const childRows = item.childRows.map((child) => ({
          child_id: `${item.id}-${child.id}`,
          ...child,
          isChild: true,
          domain: item.domain,
        }));
        return [parentRow, ...childRows];
      }
      return [parentRow];
    });
  }, [tasks, expandedRows]);
  const fetchChildData = useCallback(
    async (parentId, status = null, filters) => {
      const newFilters = {
        filters: {
          groupOp: "AND",
          rules: [
            {
              field: "bdb.task_status",
              op: "eq",
              data: status,
            },
          ],
        },
      };
      let payload;
      const getFieldCondition = (rules) => {
        if (rules.some((rule) => rule?.field === "DATE(bdb.updated_at)")) {
          return "DATE(bdb.updated_at)";
        } else if (
          rules.some((rule) => rule?.field === "DATE(bdb.assigned_date)")
        ) {
          return "DATE(bdb.assigned_date)";
        }
        return null;
      };
      if (!linkBuilderId) {
        payload = {
          user_id: linkBuilderId ? linkBuilderId : user?.user?.id,
          ...(status ? newFilters : {}),
        };
      } else {
        const fieldCondition = getFieldCondition(filters?.rules || []);
        payload = {
          user_id: linkBuilderId ? linkBuilderId : user?.user?.id,
          ...(filters?.rules?.length > 0 && fieldCondition
            ? {
                filters: {
                  groupOp: filters.groupOp,
                  rules: filters.rules.map((rule) => ({
                    ...rule,
                    field:
                      rule.field === "task_status"
                        ? "bdb.task_status"
                        : rule.field,
                  })),
                },
              }
            : { ...newFilters }),
        };
      }
      const response = await api.post(
        `/api/linkbuilder_user/task_info/${parentId}`,
        payload
      );
      return response.data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div className="px-6 pt-4 relative">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Task List", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <TaskListHeader
        filterButton={filterButton}
        tasks={tasks}
        setExpandedRows={setExpandedRows}
        setSelectedDomainId={setSelectedDomainId}
        handleStopBrowser={handleStopBrowser}
        is_browser_active={is_browser_active}
        is_browser_domain={is_browser_domain}
        linkBuilderId={linkBuilderId}
        isLoading={isLoading}
        linkBuilderUser={linkBuilderUser}
        handleLinkBuilderUserTasksList={handleLinkBuilderUserTasksList}
        LinkBuilders={LinkBuilders}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        paginationModel={paginationModel}
        fetchTasksList={fetchTasksList}
        setFilterButton={setFilterButton}
        fetchTasksWithOpenStatus={fetchTasksWithOpenStatus}
        setFilters={setFilters}
        setPaginationModel={setPaginationModel}
        filters={filters}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <div className="bg-white my-2 border rounded">
        {rows.length > 0 ? (
          rows.map((item, index) => (
            <TaskListCard
              item={item}
              expandedRows={expandedRows}
              handleExpandClick={handleExpandClick}
              handleSelectedBatch={handleSelectedBatch}
              is_browser_active={is_browser_active}
              is_browser_domain={is_browser_domain}
              timeConvert={timeConvert}
              toggleTaskStatusDropdown={toggleTaskStatusDropdown}
              openDropdownIndex={openDropdownIndex}
              updateTask={updateTask}
              setIsUrlModal={setIsUrlModal}
              setTaskStatus={setTaskStatus}
              user={user}
              setErrors={setErrors}
              linkBuilderId={linkBuilderId}
              selectedStatus={selectedStatus}
            />
          ))
        ) : (
          <div className="text-center text-gray-500 py-44">
            No records found
          </div>
        )}
      </div>
      {isUrlModal ? (
        <URLModal
          link_to_website={link_to_website}
          setLinkToWebsite={setLinkToWebsite}
          taskObj={isTaskStatus}
          onClose={() => {
            setTaskStatus({});
            setIsUrlModal(false);
            setStatusNote("");
            setLinkToWebsite("");
          }}
          updateStatus={updateTask}
          setStatusNote={setStatusNote}
          isStatusNote={isStatusNote}
          errors={errors}
        />
      ) : null}
    </div>
  );
}
