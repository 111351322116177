import React, { useEffect, useRef, useState } from "react";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import { Button, Tooltip, TablePagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import dayjs from "dayjs";
import {
  MdAddCircleOutline,
  MdDownload,
  MdRemoveCircleOutline,
} from "react-icons/md";
import Loader from "../../components/Loader/Loading";
import api from "../../services/api";
import MUIDataTable from "../../components/MUIGrid";
import exportDomainReport from "../Users/AssignDomainReport/exportDomainReport";
import AssignBacklinkDetailModal from "../Domains/AssignBacklinkDetailModal";
import userPermission from "../../utils/userPermission";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import { Modal } from "../../components";
import { useSelector } from "react-redux";
import AlreadyAssignErrorModal from "../../pages/Domains/AlreadyAssignErrorModal";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
const AssignDomainsToUser = () => {
  const { user } = useSelector((state) => state.auth);
  const columnsRef = useRef();
  const location = useLocation();
  const { selectedUser } = location.state || {};
  const [selectedFilter, setSelectedFilter] = useState("total_domains");
  const [searchText, setSearchText] = useState("");
  const [assignedStatus, setAssignedStatus] = useState("");
  const initialColumns = [
    "checkbox",
    "counter",
    "domain",
    "phone",
    "state",
    "city",
    "generate_domain_by",
    "location",
    "population",
    "purchase_date",
    "expiration_date",
    "vendors",
    "inNet_vendors",
    "outNet_vendors",
    "in_area",
    "state_code",
    "domain_indexed",
    "auto_renew",
    "is_expired",
    "check_indexing",
    "pk_latest_position",
    "job_count",
    "lead_count",
    "available_to_repurchase",
    "cc_did_info",
    "industry_id",
  ];
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "is_expired",
        op: "eq",
        data: false,
      },
    ],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [isAssignDetail, setAssignDetail] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  //const [vendors, setVendors] = useState(null);
  //const [countModal, setCountModal] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  // const [isLoader, setIsLoader] = useState(false);
  const [industryDrd, setIndustryDrd] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignDomainModal, setAssignDomainModal] = useState(false);
  const [isAlreadyAssignModal, setAlreadyAssignErrorModal] = useState(false);
  const [isExistCobmiErrors, setExistCombiErros] = useState([]);
  const [userDomainCount, setUserDomainCount] = useState({
    totalDomains: 0,
    assignedDomains: 0,
    unassignedDomains: 0,
  });
  const cancelButtonRef = useRef(null);
  const cancelButtonRef1 = useRef(null);
  const handleDomainAssignModal = () => {
    setAssignDomainModal(!isAssignDomainModal);
  };
  const handleAlreadyAssignModal = () => {
    setAlreadyAssignErrorModal(!isAlreadyAssignModal);
  };
  const generateColumnVisibilityModel = (columns, selectedColumns) => {
    return columns.reduce((model, column) => {
      if (!selectedColumns.includes(column?.column) && column?.column !== "") {
        model[column.field] = false;
      }
      return model;
    }, {});
  };
  const statusOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const matchedOption = [
    { value: "NotChecked", label: "NotChecked" },
    { value: "Matched", label: "Matched" },
    { value: "NotMatched", label: "NotMatched" },
    { value: "NotInCC", label: "NotInCC" },
  ];
  const autoRenewOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    {
      value: "Domain is not active, or does not belong to this user",
      label: "Domain is not active",
    },
  ];
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted" },
  ];
  const calculateExpirationDate = (daysUntilExpiration) => {
    const currentDate = new Date();
    const expiryDate = new Date(currentDate);
    expiryDate.setDate(currentDate.getDate() + +daysUntilExpiration);

    return {
      startWith: currentDate.toISOString(), // Return as YYYY-MM-DD format
      endWith: expiryDate.toISOString(), // Return as YYYY-MM-DD format
    };
  };

  const customOperators = [
    {
      label: "Yes",
      value: "arraySizeNotZero",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
    {
      label: "No",
      value: "arraySize",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
  ];
  const get_domains = async (filters) => {
    setIsLoading(true);
    let filter = { ...filters?.filters };
    let columns = (filters?.selected_columns || selectedColumns)?.filter(
      (val) =>
        val !== "cc_did_info.number" &&
        val !== "cc_did_info.merchant" &&
        val !== "cc_did_info.ring_group"
    );
    if (
      filter?.rules?.some(
        ({ field, op }) =>
          field === "in_area" ||
          field === "expiring_in_days" ||
          field === "domain_indexed" ||
          op === "arraySize"
      )
    ) {
      filter.rules = filter.rules.map((filter) => {
        if (filter.field === "in_area") {
          return {
            ...filter,
            field: "inNet_vendors",
            op: filter.data ? "arraySizeNotZero" : "arraySize",
            data: 0,
          };
        } else if (filter.field === "expiring_in_days") {
          return {
            ...filter,
            field: "expiring_in_days",
            op: "between",
            data: calculateExpirationDate(filter?.data),
          };
        } else if (filter.field === "domain_indexed") {
          let updatedFilter = { ...filter };
          if (filter.data === "Indexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "Indexed",
            };
          } else if (filter.data === "NotIndexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "NotIndexed",
            };
          } else if (filter.data === "Checked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "not",
              data: null,
            };
          } else if (filter.data === "NotChecked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "eq",
              data: null,
            };
          }
          return updatedFilter;
        } else if (filter.op === "arraySize") {
          return {
            ...filter,
            data: 0,
          };
        } else {
          return filter;
        }
      });
    }
    try {
      const res = await api.post(`/api/domains/user_domains`, {
        ...filters,
        filters: filter?.rules?.length ? filter : null,
        user_id: user?.user?.id,
        assigned_user_id: selectedUser?.id,
        is_assigned: true,
        selected_columns: columns
          ?.toString()
          ?.replaceAll(",", " ")
          ?.replaceAll("  ", " "),
      });
      if (res.status === 200) {
        setDomains(res.data?.record);
        setUserDomainCount(res.data?.user_domain_count[0]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ constget_domains=async ~ error:", error);
      setIsLoading(false);
    }
  };

  const handleNewFilter = () => {
    /* setFilterModel({
      items: queryOptions.rules?.map(({ field, op, data }) => ({
        field: field,
        operator: operator({ operator: op, field }),
        value: data,
      })),
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    }); */
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });

    queryOptions.rules.forEach((rule) => {
      if (rule.field === "latest_PK_ahref_keyword_volume.volume") {
        rule.field = "latest_PK_ahref_keyword_volume.volume";
        rule.data = rule.data === "-1" ? null : parseInt(rule.data);
      }
      if (rule.field === "latest_PK_ahref_keyword_difficulty.difficulty") {
        rule.field = "latest_PK_ahref_keyword_difficulty.difficulty";
        rule.data = rule.data === "-1" ? null : parseInt(rule.data);
      }
      if (rule.field === "pk_latest_position.position") {
        rule.field = "pk_latest_position.position";
        rule.data = parseInt(rule.data);
      }
    });

    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    setFilterModel(filterModel);
    if (filterModel?.items?.length === 0) {
      get_domains({
        ...sortingModel,
        filters: null,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "pk_latest_position.position"
              ? +rule.value
              : rule.value || rule.value === 0 || rule.value === false
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  const get_industry_drd = async () => {
    try {
      const res = await api.get(
        "https://api.logicalcrm.com/api/robo_industry/robo_drd"
      );
      if (res.status === 200) {
        setIndustryDrd(res.data);
      }
    } catch (err) {
      console.log("👊 ~ constget_industry_drd=async ~ err:", err);
    }
  };
  useEffect(() => {
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: paginationModel?.page,
      size: paginationModel.pageSize,
    });
    get_industry_drd();
    // eslint-disable-next-line
  }, []);
  const domainList = domains?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const onSelectAll = (checked) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = domainList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === item.domain)?.length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ domain }) => domain !== data?.domain
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const calculateDaysUntilExpiration = (expirationDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(expirationDate);

    const diffTime = expiryDate - currentDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Floor to get complete days

    return diffDays;
  };
  const checkboxColumn = [
    {
      field: "checkbox",
      column: "",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === domainList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 10,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoading ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
      hideable: false,
    },
  ];
  const columnDefs = [
    ...checkboxColumn,
    {
      field: "counter",
      column: "",
      headerName: "#",
      type: "string",
      minWidth: 60,
      maxWidth: 80,
      filterable: false,
      hideable: false,
    },
    {
      field: "domain",
      column: "domain",
      headerName: "Domain",
      hideable: false,
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-black no-underline hover:underline cursor-poi"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 250,
    },
    {
      field: "phone",
      column: "phone",
      headerName: "Phone",
      type: "string",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params?.row?.phone}</span>
          {`1${params.row.phone}` !== params?.row?.cc_did_info?.number ? (
            <small className="text-red-500">CC Number not matched</small>
          ) : null}
        </div>
      ),
    },
    {
      field: "state",
      column: "state",
      headerName: "State",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "city",
      column: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Generated By",
      column: "generate_domain_by",
      field: "generate_domain_by",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        generate_domain_by: value?.value,
      }),
      valueOptions: [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
      ],
    },
    {
      headerName: "Domain Location",
      column: "location",
      field: "location",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Population",
      column: "population",
      field: "population",
      flex: 1,
      disableColumnMenu: true,
      type: "number",
      minWidth: 100,
    },
    {
      headerName: "Purchased date",
      column: "purchase_date",
      field: "purchase_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.purchase_date),
      renderCell: (params) => {
        const rawDate = params.row.purchase_date;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "Expiration",
      column: "expiration_date",
      field: "expiration_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.expiration_date),
      renderCell: (params) => {
        const rawDate = params.row.expiration_date;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "Vendor Count",
      column: "vendors",
      field: "vendors",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            /*  onClick={() =>
              setVendors({
                vendors: params?.row?.vendors,
                domain: params?.row["domain"],
              })
            } */
          >
            {params?.row?.vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "InNet Vendors",
      column: "inNet_vendors",
      field: "inNet_vendors",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.inNet_vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            /*  onClick={() =>
              setVendors({
                vendors: params?.row?.inNet_vendors,
                domain: params?.row["domain"],
              })
            } */
          >
            {params?.row?.inNet_vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "OutNet Vendors",
      column: "outNet_vendors",
      field: "outNet_vendors",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.outNet_vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            /* onClick={() =>
              setVendors({
                vendors: params?.row?.outNet_vendors,
                domain: params?.row["domain"],
              })
            } */
          >
            {params?.row?.outNet_vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "In/Out Area",
      column: "in_area",
      field: "in_area",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: true, label: "In Area" },
        { value: false, label: "Out Area" },
      ],
      renderCell: (params) => (
        <span>
          {params?.row?.inNet_vendors?.length ? "In Area" : "Out Area"}
        </span>
      ),
    },
    {
      field: "state_code",
      column: "state_code",
      headerName: "State Code",
      flex: 1,
      minWidth: 95,
    },
    {
      field: "server_ip",
      column: "server_ip",
      headerName: "GSC IP",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "gsc_server_sync_status",
      column: "gsc_server_sync_status",
      headerName: "GSC Status",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "auth_email",
      column: "auth_email",
      headerName: "Cloudflare Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "cloudflare_zone_id",
      column: "cloudflare_zone_id",
      headerName: "Zone ID",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "cloudflare_verification_status",
      column: "cloudflare_verification_status",
      headerName: "Cloudflare Verify",
      flex: 1,
      minWidth: 200,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        cloudflare_verification_status: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Done", label: "Done" },
        { value: "Error", label: "Error" },
      ],
    },

    {
      headerName: "Indexed",
      column: "domain_indexed",
      field: "domain_indexed",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_indexed: value?.value,
      }),
      valueOptions: [
        { value: "Indexed", label: "Indexed" },
        { value: "NotIndexed", label: "Not Indexed" },
        { value: "Checked", label: "Checked" },
        { value: "NotChecked", label: "Not Checked" },
      ],
      renderCell: (params) =>
        params.row?.domain_indexed ? (
          params.row?.domain_indexed?.is_indexed === "NotIndexed" ||
          params.row?.domain_indexed?.is_indexed === "NotChecked" ? (
            <div className="flex flex-col">
              <span
              // className="text-blue-600 hover:underline cursor-pointer"
              // onClick={() => getLatestIndex(params.row.domain)}
              >
                {params?.row?.domain_indexed?.is_indexed}
              </span>
              {params?.row?.records?.check_indexing ? (
                <span>{"In Process"}</span>
              ) : (
                <span>
                  {dayjs(params?.row?.domain_indexed?.on_date).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                </span>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <span>{params?.row?.domain_indexed?.is_indexed}</span>
              {params?.row?.records?.check_indexing ? (
                <span>{"In Process"}</span>
              ) : (
                <span>
                  {dayjs(params?.row?.domain_indexed?.on_date).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                </span>
              )}
            </div>
          )
        ) : (
          <span>{"Not Checked"}</span>
        ),
    },
    {
      field: "expiring_in_days",
      column: "expiring_in_days",
      headerName: "Expiring in Days",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) => (
        <span>
          {calculateDaysUntilExpiration(params?.row?.records?.expiration_date)}
        </span>
      ),
    },
    {
      field: "is_expired",
      column: "is_expired",
      headerName: "Expired",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) => params.row.is_expired,
      valueFormatter: (params) => params.value,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <span>{params.row.is_expired}</span>
            <span>
              {params?.row?.available_to_repurchase
                ? params?.row?.available_to_repurchase.isAvailable
                  ? `Available in  $${
                      params?.row?.available_to_repurchase.price
                    } on ${dayjs(
                      params?.row?.available_to_repurchase.on_date
                    ).format("YYYY-MM-DD HH:mm")}`
                  : `Not Availabe on ${dayjs(
                      params?.row?.available_to_repurchase.on_date
                    ).format("YYYY-MM-DD HH:mm")}`
                : null}
            </span>
          </div>
        );
      },
    },
    {
      field: "available_to_repurchase.isAvailable",
      column: "available_to_repurchase",
      headerName: "Available to Repurhcase",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) =>
        params.row["available_to_repurchase.isAvailable"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "auto_renew",
      column: "auto_renew",
      headerName: "Auto Renew",
      flex: 1,
      minWidth: 120,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: autoRenewOption,
      valueGetter: (params) => params.row.auto_renew,
      valueFormatter: (params) => params.value,
    },
    {
      field: "industry_id",
      column: "industry_id",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params.row.industry_id,
      renderCell: (params) => params.row.industry_name,
      valueSetter: (params) => {
        const industry = industryDrd.find((ind) => ind._id === params.value);
        return { ...params.row, industry_id: industry ? industry._id : "" };
      },
    },
    {
      headerName: "DR",
      column: "latest_ahref_domain_DR",
      field: "latest_ahref_domain_DR",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_domain_DR?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_DR?.length > 0 ? (
          <div>
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_DR &&
                params?.row?.latest_ahref_domain_DR !== -1
                  ? params?.row?.latest_ahref_domain_DR
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_DR?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, DR: ${item["DR"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_DR?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_DR_status !== "Complete" ? (
              <span>{params.row.ahref_domain_DR_status}</span>
            ) : null}
          </div>
        ) : (
          <span>
            {params?.row?.latest_ahref_domain_DR &&
            params?.row?.latest_ahref_domain_DR !== -1
              ? params?.row?.latest_ahref_domain_DR
              : "N/A"}
          </span>
        ),
    },
    {
      field: "phone_matched_with_cc_status",
      column: "phone_matched_with_cc_status",
      headerName: "CC Number Mismatch",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: matchedOption,
      valueGetter: (params) => params.row.phone_matched_with_cc_status,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "CC Number",
      column: "cc_did_info",
      field: "cc_did_info.number",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 130,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row["cc_did_info.number"]}</span>
          {`1${params.row.phone}` !== params.row.cc_did_info?.number ? (
            <small className="text-red-500">Phone not matched</small>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "CC Merchant",
      column: "cc_did_info",
      field: "cc_did_info.merchant",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      headerName: "CC Ring Group",
      column: "cc_did_info",
      field: "cc_did_info.ring_group",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      headerName: "Domain Backlinks",
      column: "latest_ahref_domain_Backlink",
      field: "latest_ahref_domain_Backlink",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 150,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_domain_Backlink?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_Backlink?.length > 0 ? (
          <div className="w-auto min-w-[100px]">
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_Backlink &&
                params?.row?.latest_ahref_domain_Backlink !== -1
                  ? params?.row?.latest_ahref_domain_Backlink
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_Backlink?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, Backlinks: ${item["Backlinks"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_Backlink?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_Backlink_status !== "Complete" ? (
              <span>{params.row.ahref_domain_Backlink_status}</span>
            ) : null}
          </div>
        ) : (
          <span>
            {params?.row?.latest_ahref_domain_Backlink &&
            params?.row?.latest_ahref_domain_Backlink !== -1
              ? params?.row?.latest_ahref_domain_Backlink
              : "N/A"}
          </span>
        ),
    },
    {
      headerName: "PK Difficulty",
      column: "latest_PK_ahref_keyword_difficulty",
      field: "latest_PK_ahref_keyword_difficulty.difficulty",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 120,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_difficulty, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.latest_PK_ahref_keyword_difficulty ? (
          <div className="flex flex-col">
            <span>
              {params?.row?.latest_PK_ahref_keyword_difficulty?.difficulty
                ? params?.row?.latest_PK_ahref_keyword_difficulty?.difficulty
                : "-1"}
            </span>
            <span>
              {params?.row?.latest_PK_ahref_keyword_difficulty?.log_date
                ? dayjs(
                    params?.row?.latest_PK_ahref_keyword_difficulty?.log_date
                  ).format("YYYY-MM-DD HH:mm")
                : null}
            </span>
          </div>
        ) : (
          <span>{"N/A"}</span>
        ),
    },
    {
      headerName: "PK Volume",
      column: "latest_PK_ahref_keyword_volume",
      field: "latest_PK_ahref_keyword_volume.volume",
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 120,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_volume, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.latest_PK_ahref_keyword_volume ? (
          <div className="flex flex-col">
            <span>
              {params?.row?.latest_PK_ahref_keyword_volume?.volume
                ? params?.row?.latest_PK_ahref_keyword_volume?.volume
                : "-1"}
            </span>
            <span>
              {params?.row?.latest_PK_ahref_keyword_volume?.log_date
                ? dayjs(
                    params?.row?.latest_PK_ahref_keyword_volume?.log_date
                  ).format("YYYY-MM-DD HH:mm")
                : null}
            </span>
          </div>
        ) : (
          <span>{"N/A"}</span>
        ),
    },
    {
      headerName: "Primary Keyword",
      column: "primary_keyword",
      field: "primary_keyword",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      field: "delete_status",
      column: "delete_status",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => params.value?.delete_status,
      valueGetter: (params) => params.row?.delete_status,
      valueFormatter: (params) => params.value?.delete_status,
    },
    {
      field: "runner_name",
      column: "runner_name",
      headerName: "Runner Name",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "county",
      column: "county",
      headerName: "County",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Address",
      column: "address",
      field: "address",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: "zip_count",
      column: "zip_count",
      headerName: "Zip Count",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },

    {
      headerName: "Latest Position",
      column: "pk_latest_position",
      field: "pk_latest_position.position",
      flex: 1,
      disableColumnMenu: true,
      type: "number",
      align: "left",
      headerAlign: "left",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <div className="flex flex-row gap-x-1 justify-between w-full">
            <span>{params?.row?.pk_latest_position?.position}</span>
            <button
              // onClick={() => getLatestPosition(params?.row?.domain)}
              className="float-right"
            >
              <MdDownload />
            </button>
          </div>
          <span>
            {params?.row?.pk_latest_position
              ? dayjs(params?.row?.pk_latest_position?.on_date).format(
                  "YYYY-MM-DD HH:mm"
                )
              : null}
          </span>
        </div>
      ),
    },

    {
      headerName: "DNS Server IP",
      column: "DNSServerIP",
      field: "DNSServerIP",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Positioning Status",
      column: "positioning_status",
      field: "positioning_status",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: "deployed_on_host",
      column: "deployed_on_host",
      headerName: "Deployed on",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Search Volume",
      column: "search_volume",
      field: "search_volume",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },

    {
      field: "expiration_update_date",
      column: "expiration_update_date",
      headerName: "Expiration Update",
      flex: 1,
      minWidth: 95,
    },
    {
      headerName: "Leads",
      column: "lead_count",
      field: "lead_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["lead_count"] ? params?.row["lead_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["lead_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            /*  onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Leads",
                domain: params?.row["domain"],
              })
            } */
          >
            {params?.row["lead_count"] ? params?.row["lead_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      headerName: "Jobs",
      column: "job_count",
      field: "job_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["job_count"] ? params?.row["job_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["job_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            /* onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Jobs",
                domain: params?.row["domain"],
              })
            } */
          >
            {params?.row["job_count"] ? params?.row["job_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
  ];
  const [visibleColumns, setVisibleColumns] = useState(
    generateColumnVisibilityModel(
      columnDefs?.map((column) => column),
      selectedColumns
    )
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize, page: 1 });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: +params.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const scrollRef = useRef(null);
  const handleSelectColumn = (column) => {
    const scrollTop = scrollRef.current.scrollTop;
    setSelectedColumns((prevSelected) =>
      prevSelected.includes(column)
        ? prevSelected.filter((col) => col !== column)
        : [...prevSelected, column]
    );
    setTimeout(() => {
      scrollRef.current.scrollTop = scrollTop;
    }, 0);
  };
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setAnchorEl(false);
    }
  };
  const handleClickOutside = (event) => {
    if (columnsRef.current && !columnsRef.current.contains(event.target)) {
      setAnchorEl(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0 justify-between">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <div className="relative">
              <Button
                variant="text"
                onClick={handleMenuOpen}
                startIcon={<ViewColumnIcon fontSize="small" />}
                sx={{
                  borderRadius: "6px",
                  marginRight: "4px",
                  border: "1px solid",
                  borderColor: "#e8eaee",
                  height: "26px",
                }}
              >
                Columns
              </Button>
              <GridToolbarDensitySelector
                sx={{
                  borderRadius: "6px",
                  marginRight: "4px",
                  border: "1px solid",
                  borderColor: "#e8eaee",
                  height: "26px",
                }}
              />
              {anchorEl ? (
                <div
                  className="absolute shadow bg-white z-10 "
                  ref={columnsRef}
                >
                  <div
                    ref={scrollRef}
                    className="h-[350px] overflow-y-auto flex flex-col p-2 min-w-[350px] w-auto"
                  >
                    {(columnDefs?.filter(({ column }) => !!column) || []).map(
                      ({ column, headerName }, index) => (
                        <div key={index}>
                          <div
                            className={`flex items-center !py-1.5 !px-2.5 `}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleSelectColumn(column);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedColumns?.includes(column)}
                              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2 disabled:text-gray-200 disabled:cursor-not-allowed`}
                              disabled={column === "domain"}
                            />
                            <label
                              className={`text-base text-[#495057] cursor-pointer `}
                              htmlFor={column}
                            >
                              {headerName}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="text-blue-600 p-2 flex justify-between shadow-md border-t">
                    <div className="gap-x-3 flex w-full">
                      <button
                        onClick={() =>
                          setSelectedColumns([
                            ...([...columnDefs, "cc_did_info"] || []).map(
                              ({ column }) => column
                            ),
                            "check_indexing",
                          ])
                        }
                      >
                        Select All
                      </button>
                      <button onClick={() => setSelectedColumns(["domain"])}>
                        Clear
                      </button>
                    </div>
                    <button
                      onClick={() => {
                        setVisibleColumns(
                          generateColumnVisibilityModel(
                            columnDefs?.map((column) => column),
                            selectedColumns
                          )
                        );
                        setAnchorEl(false);
                        get_domains({
                          ...sortingModel,
                          filters: queryOptions,
                          page: paginationModel.page,
                          size: paginationModel.pageSize,
                        });
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="w-fit flex items-center">
              <GridToolbarFilterButton
                sx={{
                  borderRadius: "6px",
                  marginRight: "4px",
                  border: "1px solid",
                  borderColor: "#e8eaee",
                  height: "26px",
                  fontSize: "0.8125rem",
                }}
                ref={setFilterButtonEl}
              />
              <Button
                variant="text"
                onClick={handleNewFilter}
                startIcon={<FaSearch size={16} />}
                sx={{
                  borderRadius: "6px",
                  marginRight: "4px",
                  border: "1px solid",
                  borderColor: "#e8eaee",
                  height: "26px",
                  fontSize: "0.8125rem",
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
                // className="!text-[#042a42]"
              >
                Apply filter
              </Button>
              {userPermission("Assign Domains") ? (
                <Button
                  disabled={selectedData?.length === 0}
                  variant="text"
                  onClick={() => handleDomainToUser()}
                  startIcon={<MdAddCircleOutline size={16} />}
                  sx={{
                    borderRadius: "6px",
                    marginRight: "4px",
                    border: "1px solid",
                    borderColor: "#e8eaee",
                    height: "26px",
                    fontSize: "0.8125rem",
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                >
                  Assign Domains
                </Button>
              ) : null}
              {userPermission("UnAssign Domains") ? (
                <Button
                  disabled={selectedData?.length === 0}
                  variant="text"
                  onClick={() => handleDomainToUserRemove()}
                  startIcon={<MdRemoveCircleOutline size={16} />}
                  sx={{
                    borderRadius: "6px",
                    marginRight: "4px",
                    border: "1px solid",
                    borderColor: "#e8eaee",
                    height: "26px",
                    fontSize: "0.8125rem",
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                >
                  UnAssign Domains
                </Button>
              ) : null}
            </div>
          </div>
          <div className="flex w-fit gapx-2 items-center">
            <TablePagination
              color="primary"
              shape="rounded"
              size="medium"
              showFirstButton
              showLastButton
              count={
                domains?.totalItems
                  ? domains?.totalItems
                  : domainList?.length || 0
              }
              page={paginationModel.page - 1 || 0}
              onPageChange={(e, page) => handleTopPageChange(page)}
              rowsPerPage={paginationModel.pageSize || 0}
              onRowsPerPageChange={(e) =>
                handleTopPageSizeChange(e.target.value)
              }
              component="div"
              rowsPerPageOptions={[
                25,
                50,
                75,
                100,
                250,
                500,
                1000,
                1500,
                2000,
                domains?.totalItems
                  ? domains?.totalItems
                  : domainList?.length || 0,
              ]}
            />
            <Button
              variant="text"
              onClick={exportToExcel}
              // startIcon={<MdDownload size={14} />}
              sx={{ fontSize: "0.8125rem" }}
              className="!text-[#042a42]"
            >
              Export
            </Button>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: getSortField(params[0]?.field),
      sort_by: params[0]?.sort || "default",
    });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: getSortField(params[0]?.field),
      sort_by: params[0]?.sort || "default",
    });
  };

  const getSortField = (field) => {
    let sort_field = field?.includes("_in")
      ? field.replaceAll("_in", "")
      : field;
    switch (field) {
      case "pk_latest_position":
        sort_field = "pk_latest_position.position";
        break;
      case "latest_PK_ahref_keyword_difficulty":
        sort_field = "latest_PK_ahref_keyword_difficulty.difficulty";
        break;
      case "latest_PK_ahref_keyword_volume":
        sort_field = "latest_PK_ahref_keyword_volume.volume";
        break;
      default:
        break;
    }

    return sort_field;
  };

  const exportToExcel = () => {
    const getFilteredValues = (selectedColumns, columnDefs, key) => {
      const orderedColumns = columnDefs
        .filter(
          (def) =>
            selectedColumns.includes(def.column) &&
            def?.column !== "" &&
            def?.column !== "available_to_repurchase" &&
            def?.column !== "phone_matched_with_cc_status"
        )
        .map((def) => def[key]);

      return orderedColumns;
    };

    const parentHeader = [
      "Sr",
      ...getFilteredValues(selectedColumns, columnDefs, "headerName"),
    ];
    const fieldValues = [
      "sr",
      ...getFilteredValues(selectedColumns, columnDefs, "field"),
    ];

    const items = domainList.map((domainData, index) => {
      const {
        domain = " ",
        phone = " ",
        state = " ",
        city = " ",
        location = " ",
        population = " ",
        purchase_date = " ",
        expiration_date = " ",
        vendors = [],
        inNet_vendors = [],
        outNet_vendors = [],
        state_code = " ",
        domain_indexed = " ",
        is_expired = " ",
        auto_renew = " ",
        check_indexing = " ",
        generate_domain_by = " ",
        industry_id = " ",
        latest_ahref_domain_DR = " ",
        // ahref_domain_DR = [],
        latest_ahref_domain_Backlink = " ",
        // ahref_domain_Backlink = [],
        latest_PK_ahref_keyword_difficulty = " ",
        latest_PK_ahref_keyword_volume = " ",
        primary_keyword = " ",
        delete_status = " ",
        runner_name = " ",
        county = " ",
        address = " ",
        zip_count = " ",
        pk_latest_position = { position: 0 },
        DNSServerIP = " ",
        positioning_status = " ",
        deployed_on_host = " ",
        search_volume = " ",
        expiration_update_date = " ",
        lead_count = 0,
        job_count = 0,
        cloudflare_zone_id = " ",
        cloudflare_verification_status = " ",
        cloudflare_account_id = { auth_email: " " },
        GSC_server_id = { server_ip: " " },
        gsc_server_sync_status = " ",
        cc_did_info = null,
      } = domainData;

      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "" : value;

      return {
        sr: (index + 1).toString(),
        domain: addNAIfEmpty(domain),
        phone: addNAIfEmpty(phone),
        state: addNAIfEmpty(state),
        city: addNAIfEmpty(city),
        location: addNAIfEmpty(location),
        population: addNAIfEmpty(population),
        purchase_date: addNAIfEmpty(purchase_date),
        expiration_date: addNAIfEmpty(expiration_date),
        vendors: vendors?.length ?? 0,
        inNet_vendors: inNet_vendors?.length ?? 0,
        outNet_vendors: outNet_vendors?.length ?? 0,
        in_area: inNet_vendors?.length ? "In Area" : "Out Area",
        state_code: addNAIfEmpty(state_code),
        domain_indexed: domain_indexed
          ? domain_indexed.is_indexed === "NotIndexed" ||
            domain_indexed.is_indexed === "NotChecked"
            ? `${domain_indexed.is_indexed}  ${
                check_indexing
                  ? "In Process"
                  : dayjs(domain_indexed.on_date).format("YYYY-MM-DD HH:mm")
              }`
            : `${domain_indexed.is_indexed}  ${
                check_indexing
                  ? "In Process"
                  : dayjs(domain_indexed.on_date).format("YYYY-MM-DD HH:mm")
              }`
          : "Not Checked",
        is_expired: is_expired ? "Expired" : "No",
        auto_renew: addNAIfEmpty(auto_renew),
        generate_domain_by: addNAIfEmpty(generate_domain_by),
        expiring_in_days: addNAIfEmpty(
          calculateDaysUntilExpiration(expiration_date)
        ),
        industry_id: addNAIfEmpty(
          industryDrd.find((ind) => ind._id === industry_id)?.name
        ),
        latest_ahref_domain_DR:
          latest_ahref_domain_DR === null ? "N/A" : latest_ahref_domain_DR,
        latest_ahref_domain_Backlink:
          latest_ahref_domain_Backlink === null
            ? "N/A"
            : latest_ahref_domain_Backlink,
        "latest_PK_ahref_keyword_difficulty.difficulty":
          latest_PK_ahref_keyword_difficulty
            ? latest_PK_ahref_keyword_difficulty?.difficulty
              ? latest_PK_ahref_keyword_difficulty?.difficulty
              : "-1" +
                ", " +
                dayjs(latest_PK_ahref_keyword_difficulty?.log_date).format(
                  "YYYY-MM-DD HH:mm"
                )
            : "N/A",
        "latest_PK_ahref_keyword_volume.volume": latest_PK_ahref_keyword_volume
          ? latest_PK_ahref_keyword_volume?.volume
            ? latest_PK_ahref_keyword_volume?.volume
            : "-1" +
              ", " +
              dayjs(latest_PK_ahref_keyword_volume?.log_date).format(
                "YYYY-MM-DD HH:mm"
              )
          : "N/A",
        primary_keyword: addNAIfEmpty(primary_keyword),
        delete_status: addNAIfEmpty(delete_status),
        runner_name: addNAIfEmpty(runner_name),
        county: addNAIfEmpty(county),
        address: addNAIfEmpty(address),
        zip_count: addNAIfEmpty(zip_count),
        pk_latest_position: addNAIfEmpty(pk_latest_position?.position),
        DNSServerIP: addNAIfEmpty(DNSServerIP),
        positioning_status: addNAIfEmpty(positioning_status),
        deployed_on_host: addNAIfEmpty(deployed_on_host),
        search_volume: addNAIfEmpty(search_volume),
        expiration_update_date: addNAIfEmpty(expiration_update_date),
        lead_count: addNAIfEmpty(lead_count),
        job_count: addNAIfEmpty(job_count),
        auth_email: addNAIfEmpty(cloudflare_account_id?.auth_email),
        server_ip: addNAIfEmpty(GSC_server_id?.server_ip),
        cloudflare_zone_id: addNAIfEmpty(cloudflare_zone_id),
        cloudflare_verification_status: addNAIfEmpty(
          cloudflare_verification_status
        ),
        gsc_server_sync_status: addNAIfEmpty(gsc_server_sync_status),
        "cc_did_info.number": addNAIfEmpty(cc_did_info?.number),
        "cc_did_info.merchant": addNAIfEmpty(cc_did_info?.merchant),
        "cc_did_info.ring_group": addNAIfEmpty(cc_did_info?.ring_group),
        "pk_latest_position.position": addNAIfEmpty(
          pk_latest_position?.position
        ),
      };
    });

    const filteredItems = items.map((item) => {
      const filtered = {};
      fieldValues.forEach((key) => {
        if (item[key] !== undefined) {
          filtered[key] = item[key];
        }
      });
      return filtered;
    });

    exportDomainReport(filteredItems, parentHeader, "domain_reports");
  };
  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not"
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
      // data === true ? 1 : data === false ? 0 : data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });
  const [orderedColumns, setOrderedColumns] = useState(columnDefs);
  const onColumnOrderChange = React.useCallback(
    (params) => {
      const newOrder = [...orderedColumns];
      const [movedColumn] = newOrder.splice(params.oldIndex, 1);
      newOrder.splice(params.targetIndex, 0, movedColumn);
      setOrderedColumns(newOrder);
    },
    [orderedColumns]
  );
  useEffect(() => {
    // Update the columnDefs to include the updated industryDrd
    if (industryDrd?.length) {
      const updatedColumnDefs = orderedColumns.map((colDef) => {
        if (colDef.field === "industry_id") {
          return {
            ...colDef,
            valueOptions: industryDrd,
          };
        }
        return colDef;
      });
      setOrderedColumns(updatedColumnDefs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryDrd]);
  useEffect(() => {
    // Update the columnDefs to include the updated industryDrd
    const updatedColumnDefs = orderedColumns?.map((colDef) => {
      if (colDef.field === "checkbox") {
        return checkboxColumn[0];
      }
      return colDef;
    });
    setOrderedColumns(updatedColumnDefs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const existingCombinationsModal = (param = null) => {
    setExistCombiErros(param);
    setAlreadyAssignErrorModal(true);
  };

  const handleDomainToUser = (source = null, e, param = null) => {
    if (source) {
      e.preventDefault();
    }
    setIsLoading(true);
    const payload = {
      user_id: selectedUser?.id,
      domains: selectedData.map((item) => item["domain"]),
    };
    if (source && param) {
      payload.skip_existing = param;
    }
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/domains/assign_domains_to_user`, ///domains/unassign_domains_from_user
        payload
      )
      .then((res) => {
        setSelectedData([]);
        get_domains({
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
        toast.success(res?.data?.message);
        setIsLoading(false);
        if (source) setAlreadyAssignErrorModal(false);
      })
      .catch((err) => {
        if (err?.response?.data?.existingCombinations) {
          const dataArray = {
            errors: err?.response?.data?.existingCombinations || [],
            username: selectedUser?.username,
          };
          existingCombinationsModal(dataArray);
          return;
        }
        toast.error(err.response.data.message);
      });
    if (source) setAlreadyAssignErrorModal(false);
    setIsLoading(false);
  };
  const handleDomainToUserRemove = () => {
    setIsLoading(true);
    const payload = {
      user_id: selectedUser?.id,
      domains: selectedData.map((item) => item["domain"]),
    };
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/domains/unassign_domains_from_user`,
        payload
      )
      .then((res) => {
        setSelectedData([]);
        get_domains({
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
        toast.success(res?.data?.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setIsLoading(false);
  };
  /* function getCircleSize(count) {
    if (!count) count = 1;
    const digitCount = count.toString().length;
    if (digitCount === 1) {
      return "w-4 h-4 text-xs ml-2"; // Small size for 1 digit
    } else if (digitCount === 2) {
      return "w-5 h-5 text-xxs ml-2"; // Medium size for 2 digits
    } else if (digitCount === 3) {
      return "w-6 h-6 text-xxs ml-4"; // Medium size for 3 digits
    } else if (digitCount === 4) {
      return "w-7 h-7 text-xxs ml-4"; // Medium size for 4 digits
    } else if (digitCount === 5) {
      return "w-8 h-8 ml-5 text-xxs"; // Medium size for 5 digits
    } else {
      return "w-10 h-10 ml-5 text-xxs"; // Larger size for 3 or more digits
    }
  } */
  const getAssigned = (status) => {
    setSelectedFilter("");
    setAssignedStatus(status);
    setIsLoading(true);
    const filters = {
      ...sortingModel,
      filters: queryOptions,
      page: paginationModel?.page,
      size: paginationModel.pageSize,
    };
    let filter = { ...filters?.filters };
    let columns = (filters?.selected_columns || selectedColumns)?.filter(
      (val) =>
        val !== "cc_did_info.number" &&
        val !== "cc_did_info.merchant" &&
        val !== "cc_did_info.ring_group"
    );
    if (
      filter?.rules?.some(
        ({ field, op }) =>
          field === "in_area" ||
          field === "expiring_in_days" ||
          field === "domain_indexed" ||
          op === "arraySize"
      )
    ) {
      filter.rules = filter.rules.map((filter) => {
        if (filter.field === "in_area") {
          return {
            ...filter,
            field: "inNet_vendors",
            op: filter.data ? "arraySizeNotZero" : "arraySize",
            data: 0,
          };
        } else if (filter.field === "expiring_in_days") {
          return {
            ...filter,
            field: "expiring_in_days",
            op: "between",
            data: calculateExpirationDate(filter?.data),
          };
        } else if (filter.field === "domain_indexed") {
          let updatedFilter = { ...filter };
          if (filter.data === "Indexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "Indexed",
            };
          } else if (filter.data === "NotIndexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "NotIndexed",
            };
          } else if (filter.data === "Checked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "not",
              data: null,
            };
          } else if (filter.data === "NotChecked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "eq",
              data: null,
            };
          }
          return updatedFilter;
        } else if (filter.op === "arraySize") {
          return {
            ...filter,
            data: 0,
          };
        } else {
          return filter;
        }
      });
    }
    const payload = {
      user_id: selectedUser?.id,
      is_assigned: status,
      type: status ? "assigned":"notAssigned",
      ...filters,
      filters: filter?.rules?.length ? filter : null,
      selected_columns: columns
        ?.toString()
        ?.replaceAll(",", " ")
        ?.replaceAll("  ", " "),
    };
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/domains/user_domains`,
        payload
      )
      .then((res) => {
        setDomains(res.data?.record);
        setUserDomainCount(res.data?.user_domain_count[0]);
        toast.success(res?.data?.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Users", link: "/settings/users" },
          {
            label: ` Assign Domains to ${
              selectedUser ? selectedUser?.name : ""
            }`,
            link: "",
          },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      {isAssignDetail && isAssignDetail?.backlinks?.length ? (
        <AssignBacklinkDetailModal
          onClose={() => setAssignDetail(null)}
          isAssignDetail={isAssignDetail}
        />
      ) : null}
      <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5 mt-3">
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            selectedFilter === "total_domains"
              ? "border-l-4 border-secondary"
              : ""
          }
          `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_domains");
          }}
        >
          Active (InArea + OutArea)
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {domains?.summary?.activeDomainCount
              ? domains?.summary?.activeDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            selectedFilter === "total_expired"
              ? "border-l-4 border-secondary"
              : ""
          }
          `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "is_expired",
                  op: "eq",
                  data: true,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_expired");
          }}
        >
          Expired
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {domains?.summary?.expiredDomainCount
              ? domains?.summary?.expiredDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            selectedFilter === "inAreaDomainCount"
              ? "border-l-4 border-secondary"
              : ""
          }
          `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "in_area",
                  op: "eq",
                  data: true,
                },
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("inAreaDomainCount");
          }}
        >
          Active InArea
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {domains?.summary?.inAreaDomainCount
              ? domains?.summary?.inAreaDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            selectedFilter === "outAreaDomainCount"
              ? "border-l-4 border-secondary"
              : ""
          }
          `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "in_area",
                  op: "eq",
                  data: false,
                },
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("outAreaDomainCount");
          }}
        >
          Active OutArea
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {domains?.summary?.outAreaDomainCount
              ? domains?.summary?.outAreaDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            assignedStatus === true ? "border-l-4 border-secondary" : ""
          }
          `}
          onClick={(e) => getAssigned(true)}
        >
          Assigned
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {userDomainCount ? userDomainCount?.assignedDomains : 0}
          </span>
        </button>
        <button
          type="button"
          className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable bg-white text-black ${
            assignedStatus === false ? "border-l-4 border-secondary" : ""
          }
          `}
          onClick={(e) => getAssigned(false)}
        >
          Not Assigned
          <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
            {userDomainCount ? userDomainCount?.unassignedDomains : 0}
          </span>
        </button>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={orderedColumns}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              _id,
              domain,
              industry_id,
              primary_keyword,
              ahref_PK_keyword_volume,
              latest_PK_ahref_keyword_volume,
              ahref_PK_keyword_difficulty,
              latest_PK_ahref_keyword_difficulty,
              ahref_domain_DR_status,
              ahref_domain_DR,
              latest_ahref_domain_DR,
              ahref_domain_Backlink_status,
              ahref_domain_Backlink,
              latest_ahref_domain_Backlink,
              delete_status,
              runner_name,
              phone,
              city,
              state,
              county,
              zip_count,
              location,
              population,
              address,
              vendor_count,
              outNet_vendors,
              inNet_vendors,
              in_area,
              DNSServerIP,
              pk_latest_position,
              job_count,
              lead_count,
              search_volume,
              generate_domain_by,
              domain_indexed,
              positioning_status,
              deployed_on_host,
              purchase_date,
              expiration_date,
              expiration_update_date,
              is_expired,
              available_to_repurchase,
              valueserp_BatchID,
              state_code,
              vendors,
              auto_renew,
              cloudflare_account_id,
              GSC_server_id,
              cloudflare_zone_id,
              cloudflare_verification_status,
              gsc_server_sync_status,
              cc_did_info,
            } = record;
            const sortByDate = (arr) =>
              (arr?.filter((pos) => pos !== null) || []).sort(
                (a, b) => new Date(b.log_date) - new Date(a.log_date)
              );
            return {
              records: { ...record },
              counter: offset + counter,
              _id,
              domain,
              industry_id,
              industry_name: industryDrd.find((ind) => ind._id === industry_id)
                ?.name,
              primary_keyword,
              ahref_domain_DR_status,
              latest_PK_ahref_keyword_volume,
              latest_PK_ahref_keyword_difficulty,
              latest_ahref_domain_DR,
              ahref_domain_Backlink_status,
              latest_ahref_domain_Backlink,
              delete_status,
              runner_name,
              phone,
              city,
              state,
              county,
              zip_count,
              server_ip: GSC_server_id?.server_ip,
              auth_email: cloudflare_account_id?.auth_email,
              ahref_PK_keyword_volume: sortByDate(ahref_PK_keyword_volume),
              ahref_PK_keyword_difficulty: sortByDate(
                ahref_PK_keyword_difficulty
              ),
              gsc_server_sync_status,
              ahref_domain_DR: sortByDate(ahref_domain_DR),
              ahref_domain_Backlink: sortByDate(ahref_domain_Backlink),
              location,
              population,
              address,
              vendor_count,
              outNet_vendors,
              inNet_vendors,
              in_area,
              vendors,
              DNSServerIP,
              pk_latest_position,
              job_count,
              lead_count,
              search_volume,
              generate_domain_by,
              domain_indexed,
              positioning_status,
              deployed_on_host,
              purchase_date,
              expiration_date,
              expiration_update_date,
              is_expired: is_expired ? "Yes" : "No",
              valueserp_BatchID,
              available_to_repurchase,
              state_code,
              auto_renew,
              cloudflare_zone_id,
              cloudflare_verification_status,
              cc_did_info,
              "cc_did_info.number": cc_did_info?.number,
              "cc_did_info.merchant": cc_did_info?.merchant,
              "cc_did_info.ring_group": cc_did_info?.ring_group,
            };
          })}
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={domains?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          //isLoading={loading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          columnVisibilityModel={{
            ...visibleColumns,
            "available_to_repurchase.isAvailable": false,
            phone_matched_with_cc_status: false,
          }}
          height="100vh"
          maxHeight="90vh"
          density="standard"
          filterModel={filterModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          onColumnOrderChange={onColumnOrderChange}
        />
      </div>
      {isAssignDetail && isAssignDetail?.backlinks?.length ? (
        <AssignBacklinkDetailModal
          onClose={() => setAssignDetail(null)}
          isLoading={isLoading}
          isAssignDetail={isAssignDetail}
        />
      ) : null}
      <Modal
        open={!isAssignDomainModal}
        handleModal={handleDomainAssignModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-5xl "
        title="Assign Domains to User"
      >
        {/*  <DomainAssignModal
          selectedData={selectedData}
          assignDomainModal={assignDomainModal}
          existingCombinationsModal={existingCombinationsModal}
        /> */}
      </Modal>
      <Modal
        open={!isAlreadyAssignModal}
        handleModal={handleAlreadyAssignModal}
        cancelButtonRef={cancelButtonRef1}
        className="max-w-2xl"
        title={
          <span className="text-red-500 text-lg">
            Below domains are already assigned to {isExistCobmiErrors?.username}
            !
          </span>
        }
      >
        <AlreadyAssignErrorModal
          isExistCobmiErrors={isExistCobmiErrors}
          handleDomainToUser={handleDomainToUser}
          setAlreadyAssignErrorModal={setAlreadyAssignErrorModal}
        />
      </Modal>
    </div>
  );
};

export default AssignDomainsToUser;
