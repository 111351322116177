import { Tooltip } from "@mui/material";
import Loader from "../../components/Loader/Loading";
export const columns = ({
  //handleModal,
  backlinksTypeList,
  backlinksStatusList,
  followStatus,
  handleTaskLink,
  handleToggle,
  toggleStates,
  backlinksData,
  selectedData,
  onSelectAll,
  isSelected,
  onSingleSelect,
  isLoading,
}) => [
  {
    field: "checkbox",
    column: "",
    renderHeader: (params) => (
      <input
        type="checkbox"
        onChange={(e) => onSelectAll(e.target.checked)}
        className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        checked={selectedData?.length === backlinksData?.length}
      />
    ),
    filterable: false,
    sortable: false,
    width: 10,
     renderCell: (params) => (
      <div className="flex justify-center w-full items-center">
        {isSelected(params.row.records) && isLoading ? (
          <Loader />
        ) : (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        )}
      </div>
    ), 
    disableColumnMenu: true,
    hideable: false,
  },
  { field: "counter", headerName: "#", filterable: false, width: 60 },
  {
    field: "b.backlink_domain",
    headerName: "Backlink Domain",
    minWidth: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <a
          href={`https://www.${params.row["b.backlink_domain"]}`}
          className="hover:text-secondary underline"
          target="_blank"
          rel="noreferrer"
        >
          {params.row["b.backlink_domain"]}
        </a>
      );
    },
  },
  // { field: "city", headerName: "City", width: 120, editable: false },
  // { field: "state", headerName: "State", width: 120, editable: false },
  // {
  //   field: "b.signup_url",
  //   headerName: "Signup URL",
  //   width: 100,
  //   editable: true,
  //   filterable: false,
  // },
  // {
  //   field: "source_backlink_domains_list_count",
  //   headerName: "SD",
  //   width: 60,
  //   align: "center",
  //   headerAlign: "center",
  //   renderCell: (params) => (
  //     <span
  //       className="text-[#2563eb] cursor-pointer hover:underline"
  //       onClick={() =>
  //         handleModal(params?.row.records["bi.source_domains_list"])
  //       }
  //     >
  //       {params?.row?.source_domains_list_count}
  //     </span>
  //   ),
  //   filterable: false,
  // },
  /*  {
    field: "b.backlink_location_type",
    headerName: "Backlink Location Type",
    width: 100,
    editable: true,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">
              Backlink Location Type
            </div>
          }
        >
          <span>BL Type</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "b.backlink_rank",
    headerName: "Backlink Rank",
    width: 120,
    flex: 1,
    editable: true,
    filterable: false,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">Backlink Rank</div>
          }
        >
          <span>B Rank</span>
        </Tooltip>
      </div>
    ),
  }, */
  {
    field: "b.ready_status_id",
    headerName: "Backlink Ready Status",
    minWidth: 100,
    flex: 1,
    editable: true,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.ready_status,
    valueOptions: backlinksStatusList,
    renderCell: (params) => {
      const status = backlinksStatusList.find(
        (status) => status.id === params.row["b.ready_status_id"]
      );
      return status ? status.ready_status : "";
    },
    valueGetter: (params) => params.row["b.ready_status_id"],
    valueSetter: (params) => {
      if (params.value) {
        const { value } = params;
        const status = backlinksStatusList.find(
          (status) => status.id === value
        );
        if (status) {
          const updatedRow = {
            ...params?.row,
            "b.ready_status_id": params?.value,
            "b.ready_status": status?.ready_status,
          };
          return updatedRow;
        }
      }
      return params;
    },
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.good_to_assign",
    headerName: "Good To Assign",
    width: 120,
    editable: false,
    type: "singleSelect",
    minWidth: 60,
    disableColumnMenu: true,
    align: "center",
    valueOptions: [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
    getOptionValue: (option) => option?.value,
    getOptionLabel: (option) => option.label,
    renderCell: (params) => {
      const isChecked =
        toggleStates[params.id] !== undefined
          ? toggleStates[params.id]
          : params.row["b.good_to_assign"] === 1;
      return (
        <label className="flex items-center relative w-max cursor-pointer select-none ">
          <input
            type="checkbox"
            checked={isChecked}
            disabled= {params?.row?.records?.isNew}
            onChange={() => handleToggle(params.id)}
            className="appearance-none transition-colors cursor-pointer w-14 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500 checked:bg-green-500"
          />
          <span className="absolute font-medium text-xxs uppercase right-1 text-white">
            {" "}
            No{" "}
          </span>
          <span className="absolute font-medium text-xxs uppercase right-8 text-white">
            {" "}
            Yes{" "}
          </span>
          <span
            className={`w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 ${
              isChecked ? "translate-x-7" : "translate-x-0"
            }`}
          />
        </label>
      );
    },
  },
  {
    field: "b.follow_status",
    headerName: "Follow Status",
    minWidth: 100,
    flex: 1,
    editable: true,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: followStatus,
    renderCell: (params) => params.value["b.follow_status"],
    valueGetter: (params) => params.row["b.follow_status"],
    valueFormatter: (params) => params.value,
  },
  // {
  //   field: "intersect_count",
  //   headerName: "IC",
  //   width: 60,
  //   editable: false,
  //   filterable: false,
  // },
  {
    field: "b.backlink_type_id",
    headerName: "Backlink Type",
    width: 120,
    editable: true,
    align: "left",
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.type,
    valueOptions: backlinksTypeList,
    renderCell: (params) => {
      const status = backlinksTypeList.find(
        (status) => status.id === params.row["b.backlink_type_id"]
      );
      return status ? status.type : "";
    },
    valueGetter: (params) => params.row["b.backlink_type_id"],
    valueSetter: (params) => {
      if (params.value) {
        const { value } = params;
        const status = backlinksTypeList.find((status) => status.id === value);
        if (status) {
          const updatedRow = {
            ...params?.row,
            "b.backlink_type_id": params?.value,
            "b.type": status?.type,
          };
          return updatedRow;
        }
      }
      return params;
    },
    valueFormatter: (params) => params.value,
  },
  {
    field: "bds.assigned_users",
    headerName: "Task Assign",
    width: 200,
    editable: false,
    type: "singleSelect",
    minWidth: 60,
    disableColumnMenu: true,
    valueOptions: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    filterOperators: [
      {
        label: "Yes",
        value: "Yes",
        getApplyFilterFn: (filterItem) => {
          return (params) => {
            return params.row.bds.assigned_users.length === 0;
          };
        },
      },
      {
        label: "No",
        value: "No",
        getApplyFilterFn: (filterItem) => {
          return (params) => {
            return params.row.bds.assigned_users.length > 0;
          };
        },
      },
    ],
  },
  {
    field: "bds.total_inReview",
    headerName: "In Review Task",
    width: 120,
    editable: false,
    align: "center",
    type: "number",
    renderCell: (params) =>
      params?.row["bds.total_inReview"] > 0 ? (
        <span
          className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
          onClick={(e) => handleTaskLink(params?.row["b.id"], "inReview")}
        >
          {params?.row["bds.total_inReview"]}
        </span>
      ) : (
        <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">0</span>
      ),
  },
  {
    field: "bds.total_hold",
    headerName: "In Hold Task",
    width: 120,
    editable: false,
    align: "center",
    type: "number",
    renderCell: (params) =>
      params?.row["bds.total_hold"] > 0 ? (
        <span
          className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
          onClick={(e) => handleTaskLink(params?.row["b.id"], "hold")}
        >
          {params?.row["bds.total_hold"]}
        </span>
      ) : (
        <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">0</span>
      ),
  },
  {
    field: "b.traffic",
    headerName: "Traffic",
    width: 120,
    editable: true,
    align: "left",
    filterable: false,
  },
  {
    field: "b.domain_rating",
    headerName: "DR",
    width: 80,
    editable: true,
    filterable: false,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">Domain Rating</div>
          }
        >
          <span>DR</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "b.created_at",
    headerName: "Created At",
    width: 200,
    editable: false,
    filterable: false,
  },
  /* {
    field: "b.updated_at",
    headerName: "Updated At",
    width: 200,
    editable: false,
    filterable: false,
  }, */
];
